<template>
    <el-dialog :title="member === 'campuses' ? '添加校区':'添加学段'" :visible.sync="openDialog" width="450px" :show-close="false" class="dialog-vertical" 
    @close="cancel">
        <el-form label-width="80px" class="filter-from" >
            <div class="filter-item">
                <el-form-item required class="" :label="member === 'campuses' ? '校区名称：':'学段：'">
                    <el-input v-model="add" placeholder="请输入内容"></el-input>
                </el-form-item>
            </div> 
        </el-form>
        <template #footer>
            <el-button size="small" @click="openDialog = false">取消</el-button>
            <el-button size="small" type="primary" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
export default {
    model: {
        prop: 'open',
        event: "closeDialog"
    },
    props:{
        open: {
            type: Boolean,
            default: false
        },
        member: {
            type: String,
            default: ''
        },
        config: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            add: ''
        }
    },
    methods: {
        // 取消
        cancel(formName) {
          this.add = ''
          this.openDialog = false;
        },
        // 添加
        submit() {
            this.$emit('changeAdd', this.add)
            // this.openDialog = false
            this.add = ''
        },
    },
    watch: {
    },
    computed: {
        openDialog: {
          get() {
            return this.open;
          },
          set(val) {
            this.$emit("closeDialog", val);
          },
        },
    }
}
</script>

<style lang="scss" scope>

</style>