<template>
  <div class="term-config">
    <div class="list-content" v-loading="loading">
      <div class="config-item" v-if="config">
        <div class="config-title">校区、学段配置</div>
        <div class="config-content">
            <el-form class="small-form" size="small" ref="postForm" label-width="70px" label-position="left">
                <el-form-item label="校区名称：">
                    <member-tag style="margin-left: 10px" v-for="(item, index) in config" :key="index" @del="deleteCampus(item.id)">
                        {{item.name}}
                    </member-tag>
                    <el-button class="member-btn" type="primary" @click="add(1)">+添加</el-button>
                </el-form-item>
                <el-form-item label="学段：" required>
                    <member-tag  style="margin-left: 10px" v-for="(item, index) in config2" :key="index" @del="deletePeriod(item.id)">
                        {{item.name}}
                    </member-tag>
                    <el-button class="member-btn" type="primary" @click="add(2)">+添加</el-button>
                </el-form-item>
            </el-form>
        </div>
      </div>
    </div>
    <!-- <fixed-action-bar>
      <el-button type="primary" :loading="loading" @click="saveConfig">保存</el-button>
    </fixed-action-bar> -->
     <SchoolAdd v-model="show" :member="member" :config="member === 'campuses' ? config:config2" @changeAdd="changeAdd"></SchoolAdd>
  </div>
</template>

<script>
import MemberTag from "../../components/OtherConfig/MemberTag";
import FixedActionBar from "../../../../base/layout/FixedActionBar";
import SchoolAdd from "../../components/OtherConfig/SchoolAdd";
import {
  saveCampus,
  savePeriod,
  deleteCampus,
  deletePeriod,
} from "../../api/school-campus-period-config";
import {getCampusAndPeriod} from '../../api/member/member-list'
export default {
  components: { FixedActionBar, MemberTag, SchoolAdd },
  data() {
    return {
      loading: true,
      config: [],
      config2: [],
      show: false,
      member: '',
      newVal: {}
    };
  },
  
  created() {
    this.getConfig();
  },
  methods: {
    handleSelect(e) {
        this.config = e.map((item) => {
            return {
                id: item.id,
                name: item.name,
            }
        })
    },
    // 获取配置
    getConfig() {
      getCampusAndPeriod()
        .then((res) => {
          this.config = res.data.campuses
          this.config2 = res.data.periods
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 校区删除
    deleteCampus(id) {
      this.loading = true
      deleteCampus({
        id: id
      }).then(res => {
        this.$message.success(res.msg)
        this.getConfig();
        this.loading = false
      })
      .catch(err => {
        this.loading = false
      })
    },
    // 学段删除
    deletePeriod(id) {
      this.loading = true
      deletePeriod({
        id: id
      }).then(res => {
        this.$message.success(res.msg)
        this.getConfig()
        this.loading = false
      }).catch((err) => {
        this.loading = false
      })
    },
    // 添加 
    add(a) {
      this.show = true;
      if (a === 1) {
        this.member = 'campuses';
      } else {
        this.member = 'periods';
      }
    },
    changeAdd(val) {
      if (this.member === 'campuses') {
        const newVal = {id: '', name: val}
        if (val) {
          this.loading = true
          saveCampus(newVal)
            .then((res) => {
            this.$message.success(res.msg);
            this.getConfig();
            this.show = false
            this.loading = false;
          }).catch(err => console.log(err))
        } else {
          return this.$message.error('请输入校区名称')
        }
      } else {
        const newVal = {id: '', name: val}
        if (val) {
        this.loading = true
        savePeriod(newVal)
          .then((res) => {
          this.$message.success(res.msg);
          this.getConfig();
          this.show = false
          this.loading = false;
        })
        } else {
          return this.$message.error('请输入学段')
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.term-config {
  .list-item {
    min-height: 100px;
  }

  .config-item + .config-item {
    margin-top: 79px;
  }

  .config-item {
    padding-top: 20px;
    .config-title {
      padding-left: 8px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 16px;
      border-left: 3px #3479ef solid;
    }
    .config-content {
      margin-top: 32px;
      padding-left: 11px;
      .small-form {
        .member-btn {
          margin-left: 15px
        }
      }
    }
  }
}
</style>
