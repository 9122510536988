<template>
  <el-tag class="member-tag" closable @close="del">
    <slot></slot>
  </el-tag>
</template>

<script>
  export default {
    name: "MemberTag",
    props: {

    },
    data() {
      return {
        // status: true
      }
    },
    methods: {
      del() {
        // this.status = false;
        this.$emit('del')
      }
    },
  }
</script>

<style lang="scss" scoped>
.member-tag {
  /*border-color: rgba(52, 121, 239, 0.15);*/
  background-color: rgba(52, 121, 239, 0.15);

  /*&.hide {*/
  /*  display: none;*/
  /*}*/
}
</style>
